import { FC } from 'react';
import IconProps from './IconProps';

const LogoutIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6132 14.8529C15.2227 15.2434 15.2227 15.8766 15.6132 16.2671C16.0037 16.6576 16.6369 16.6576 17.0274 16.2671L15.6132 14.8529ZM20.5474 12.7471C20.9379 12.3566 20.9379 11.7234 20.5474 11.3329C20.1569 10.9424 19.5237 10.9424 19.1332 11.3329L20.5474 12.7471ZM19.1332 12.7471C19.5237 13.1376 20.1569 13.1376 20.5474 12.7471C20.9379 12.3566 20.9379 11.7234 20.5474 11.3329L19.1332 12.7471ZM17.0274 7.81291C16.6369 7.42239 16.0037 7.42239 15.6132 7.81291C15.2227 8.20344 15.2227 8.8366 15.6132 9.22713L17.0274 7.81291ZM19.8396 13.04C20.3918 13.04 20.8396 12.5923 20.8396 12.04C20.8396 11.4877 20.3918 11.04 19.8396 11.04V13.04ZM7.51956 11.04C6.96728 11.04 6.51956 11.4877 6.51956 12.04C6.51956 12.5923 6.96728 13.04 7.51956 13.04V11.04ZM13.8 15.56C13.8 15.0077 13.3523 14.56 12.8 14.56C12.2477 14.56 11.8 15.0077 11.8 15.56H13.8ZM11.8 8.52C11.8 9.07228 12.2477 9.52 12.8 9.52C13.3523 9.52 13.8 9.07228 13.8 8.52H11.8ZM17.0274 16.2671L20.5474 12.7471L19.1332 11.3329L15.6132 14.8529L17.0274 16.2671ZM20.5474 11.3329L17.0274 7.81291L15.6132 9.22713L19.1332 12.7471L20.5474 11.3329ZM19.8396 11.04H7.51956V13.04H19.8396V11.04ZM11.8 15.56V16.44H13.8V15.56H11.8ZM11.8 16.44C11.8 17.3458 11.0658 18.08 10.16 18.08V20.08C12.1704 20.08 13.8 18.4504 13.8 16.44H11.8ZM10.16 18.08H6.64V20.08H10.16V18.08ZM6.64 18.08C5.73425 18.08 5 17.3458 5 16.44H3C3 18.4504 4.6297 20.08 6.64 20.08V18.08ZM5 16.44V7.64H3V16.44H5ZM5 7.64C5 6.73426 5.73426 6 6.64 6V4C4.62969 4 3 5.62969 3 7.64H5ZM6.64 6H10.16V4H6.64V6ZM10.16 6C11.0658 6 11.8 6.73425 11.8 7.64H13.8C13.8 5.6297 12.1704 4 10.16 4V6ZM11.8 7.64V8.52H13.8V7.64H11.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoutIcon;
